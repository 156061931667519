import React, { Component, Suspense } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { apiCallingPost } from "../services";
import LocatorBannerimg from "../images/locator-banner.webp";
import Slider from "react-slick";
import logo from "../images/logo.webp";
import Phn from "../images/phone.webp";
import Close from "../images/close-icon.webp";
import checkpoxIcon from "../images/checkpox-icon.webp";
import MainBanner from "../images/bannermain.webp";
import MainBannerWhite from "../images/bannermainWhite.webp";
import ApplyButton from "../images/applybutton_axisbank.webp";
import ApplyButtonMBl from "../images/applynowbutton.webp";
import sliderImg1 from "../images/slider-banner1.jpg";
import SavingsImg from "../images/Easy-Access-and-Amaze_-SA.jpg";
import PageNotFound from "../home/page-not-found-sub"; 
import moment from "moment";
import "../css/bootstrap.css";
import "../css/slick.css";
import "../css/slick-theme.css";
// import "../css/style.css";
// import "../css/responsive.css";
import "../css/style-min.css";
import "../css/responsive-min.css";
import $ from "jquery";
import ImageLoader from "react-load-image";
import Captcha from "./captcha";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
const Dealership = React.lazy(() => import("../home/locator-dealership"));
const LocatorBranchInfo = React.lazy(() =>
  import("../home/Locator-branch-info")
);
const GoogleMapp = React.lazy(() => import('./GoogleMap'))
const Footerr = React.lazy(() => import('../layouts/footer'))
const captchaImage = new Captcha(
  "32px Roboto",
  "center",
  "middle",
  190,
  60,
  "white",
  "#574b4b",
  5
).createImage();
class Locator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      siteId: 0,
      dealerAddress: "",
      dealerPhNo: "",
      pincode: "",
      branchId: "",
      currentTimeee: moment().format("HH:mm a"),
      openTime: "",
      closedTime: "",
      categoryType: "",
      bankservices: "",
      timing: "",
      parkingOptions: "",
      bussinessHrs: "",
      servicesOptions: "",
      fuelAvailable: "",
      paymentMethod: "",
      languages: "",
      isDataFetch: "0",
      iscompleted: false,
      siteDataDetails: null,
      isMasterBanner1: "",
      isMasterBanner2: "",
      isMasterBanner3: "",
      bannerData: [],
      facebook: "",
      instagram: "",
      twitter: "",
      youtube: "",
      linkedin: "",
      mobileno: "",
      State: "",
      City: "",
      location: "",
      Address: "",
      ASMEmailID: "",
      ASM: "",
      DEMEmailID: "",
      DEMName: "",
      GMEmailID: "",
      GMName: "",
      isLoader: 0,
      longitude: 0,
      latitude: 0,
      seoTitle: "",
      seoKeywords: "",
      seoDescription: "",
      SeoSlug: "",
      SeoH1Tag: "",
      s3imageurl: "",
      cdnPath: "",
      schemaOutletType: "",
      progressBlog: "",
      bussinessHours: "",
      youtubeVideos: "",
      galleryVideos: "",
      offers: "",
      about: "",
      paymentMethods: "",
      tags: "",
      inputNumber: "",
      sameState: "",
      isActiveMobile: "0",
      allStateList: [],
      allCityStateList: [],
      placeId: "",
      loc_ratings: "",
      getDataFlag: false,
      getDataApiResult: "",
      getNearBranchesAtm: [],
      getNearLocality:  [],
      data: {
        image: captchaImage.image,
        captchaCode: captchaImage.code
      },
    };
    this.getSiteData = this.getSiteData.bind(this);
    this.getNearBranchesAtm = this.getNearBranchesAtm.bind(this);
  }
  createMarkup(source) {
    return { __html: source };
  }
  MyCaptcha() {
    if (this.state.data.image === null)
      return <p>Please click to generate a new captcha image.</p>;
    return <div dangerouslySetInnerHTML={this.createMarkup(this.state.data.image)} />;
  }
  menuClick() {
    $(".menulist").addClass("menulist-active");
  }
  menuClose() {
    $(".menulist").removeClass("menulist-active");
  }
  componentDidMount() {
    //this.getLoadData();
    if ($(window).width() <= 767) {
      this.setState({
        isActiveMobile: "1",
      }, () => {
        console.log(this.state.isActiveMobile);
      });
    } else {
      this.setState({
        isActiveMobile: "0",
      }, () => {
        console.log(this.state.isActiveMobile);
      });
    }
    $(".googleMapdiv [role=button]").remove();
    $("#my_captcha img").attr({ alt: "captcha", width: "190px", height: "60px" });
    $("#modal").on("click", function () {
      $("#applyModal").show();
    });
    $("#closeModal").on("click", function () {
      $("#applyModal").hide();
    });
    this.getSiteData();
    $("html, body").animate({ scrollTop: 0 }, 500);
  }
  Preloader() {
    return (
      <img src={MainBannerWhite} className="bannerItemImg" alt="Default banner" width="1440" height="225"></img>
    );
  }
  getSiteData = () => {
    let pathname = this.props.location.pathname;
    var path = pathname.split("/");
    path.shift();
    const apiData = {
      functionName: "getSiteDataBySlug",
      slug: path[0].replaceAll("-", " ")
    };

    apiCallingPost(apiData).then((data) => {
      if (data.data.success === "1") {
        //console.log("data.data---", data.data);
        this.setState({ getDataFlag: true, getDataApiResult: "true" })
        var bannerData = [];
        if (data.data.configData.commonBanner) {
          bannerData.push(
            data.data.configData.commonBanner.replace("~undefined", "")
          );
        }
        if (data.data.configData.commonBanner2) {
          bannerData.push(
            data.data.configData.commonBanner2.replace("~undefined", "")
          );
        }
        if (data.data.configData.commonBanner3) {
          bannerData.push(
            data.data.configData.commonBanner3.replace("~undefined", "")
          );
        }

        if (data.data.siteDatas.contentSection12) {
          var bannerSplit = data.data.siteDatas.contentSection12.split("||");
          bannerSplit.forEach((element) => {
            if (element) {
              if (element !== "null" && element !== "null^^" && element.split("^^")[0] !== "" && element.split("^^")[1] !== "") {
                bannerData.push(element);
              }
            }
          });
        }
        // var busHrs = data.data.siteDatas.customBusinessHour;
        // while (busHrs.includes("|")) {
        //   busHrs = busHrs.replace("|", "<br/>");
        // }
        // console.log(" data.data.siteDatas----",  data.data.siteDatas);
        this.setState({
          dealerPhNo: data.data.siteDatas.contentSection2,
          pincode: data.data.siteDatas.pin_code,
          branchId: data.data.siteDatas.branchId,
          categoryType: data.data.siteDatas.contentSection2.split("|")[7],
          categoryType: data.data.siteDatas.contentSection2.split("|")[7] !== undefined &&
            data.data.siteDatas.contentSection2.split("|")[7].split(",").includes("ATM")
            ? "ATM" : "",
          progressBlog: data.data.siteDatas.contentSection6,
          bussinessHours: data.data.siteDatas.contentSection5,
          youtubeVideos: data.data.siteDatas.contentSection15,
          offers: data.data.siteDatas.contentSection9,
          about: data.data.siteDatas.contentSection10,
          paymentMethods: data.data.siteDatas.contentSection14,
          tags: data.data.siteDatas.contentSection11,
          galleryVideos: data.data.siteDatas.contentSection18,
          bankservices: data.data.siteDatas.contentSection19,
          openTime: data.data.siteDatas.contentSection5.split("|")[4],
          closedTime: data.data.siteDatas.contentSection5.split("|")[5],
          Address: data.data.siteDatas.complete_address,
          ASMEmailID: data.data.siteDatas.ASMEmailID,
          ASM: data.data.siteDatas.ASM,
          DEMEmailID: data.data.siteDatas.DEMEmailID,
          DEMName: data.data.siteDatas.DEMName,
          GMEmailID: data.data.siteDatas.GMEmailID,
          GMName: data.data.siteDatas.GMName,
          siteDataDetails: data.data.siteDatas,
          siteId: data.data.siteDatas.siteId,
          iscompleted: true,
          dealerName: data.data.siteDatas.dealerName,
          City: data.data.siteDatas.City,
          location: data.data.siteDatas.location,
          dealerAddress: data.data.siteDatas.contentSection1,
          timing: data.data.siteDatas.contentSection3,
          parkingOptions: data.data.siteDatas.contentSection4,
          longitude: data.data.siteDatas.contentSection17,
          latitude: data.data.siteDatas.contentSection16,
          // weekdays: bussinessHrs[0].listing[0].contentDataSubDescription,
          // weekend: bussinessHrs[0].listing[0].contentDataSubTitle,
          bussinessHrs: data.data.siteDatas.customBusinessHour,
          servicesOptions: data.data.siteDatas.contentSection13,
          fuelAvailable: data.data.siteDatas.contentSection7,
          paymentMethod: data.data.siteDatas.contentSection8,
          languages: data.data.siteDatas.contentSection7,
          bannerData: bannerData,
          isMasterBanner1: data.data.configData.commonBanner,
          isMasterBanner2: data.data.configData.commonBanner2,
          isMasterBanner3: data.data.configData.commonBanner3,
          s3imageurl: data.data.configData.s3url,
          cdnPath: data.data.configData.cdnPath,
          isDataFetch: "1",
          facebook: data.data.siteDatas.facebookUrl,
          googlepluspage: data.data.siteDatas.googleplaceUrl,
          instagram: "",
          twitter: "",
          youtube: "",
          linkedin: "",
          City: data.data.siteDatas.City,
          location: data.data.siteDatas.location,
          seoTitle: data.data.siteDatas.seoTitle,
          seoKeywords: data.data.siteDatas.seoKeyword,
          SeoSlug: data.data.siteDatas.seoSlug,
          SeoH1Tag: data.data.siteDatas.h1Tag,
          seoDescription: data.data.siteDatas.seoDescription,
          schemaOutletType: data.data.siteDatas.outletType,
          placeId: data.data.siteDatas.placeId,
          loc_ratings: data.data.siteDatas.loc_ratings,
          State: data.data.siteDatas.State
        });
         this.getNearBranchesAtm();
      } else {
        this.setState({
          siteDataDetails: null,
          siteId: 0,
          iscompleted: true, 
          getDataApiResult: "false"
        });
      }
      this.setState({
        isLoader: 1,
      });
    });

  };

  getNearBranchesAtm = () => {
    const apiData = {
      functionName: "getnearbylocation",
      siteId: this.state.siteId
    };
    apiCallingPost(apiData).then((data) => {
      //console.log("data0----", data);
      if (data.data.success === "1") {
        let getNearLocality = data.data.result.getnearbylocationDetails
        let uniqueArray = getNearLocality.filter((obj, index, self) =>
          index === self.findIndex((t) => (
            t.location === obj.location
          ))
        );
        this.setState({
          getNearBranchesAtm: data.data.result.getnearbylocationDetails,
          getNearLocality:  uniqueArray
        })
      }
      else {
        this.setState({
          getNearBranchesAtm: []
        })
      }
    });
  };
  navigateBreadCrumb = (type) => {
    if (type === "Dealer") {
      // window.location = "/home";
      window.location = "https://www.axisbank.com/";
    } else if (type === "State") {
      // window.location =
      //   "/home_" + this.state.siteDataDetails.State.replaceAll(" ", "-");
      window.location = "https://branch.axisbank.com/";
    } else if (type === "City") {
      window.location =
        "/home_" +
        this.state.siteDataDetails.State.replaceAll(" ", "-") +
        "_" +
        this.state.siteDataDetails.City.replaceAll(" ", "-");
    } else if (type === "Location") {
      window.location =
        "/home_" +
        this.state.siteDataDetails.State.replaceAll(" ", "-") +
        "_" +
        this.state.siteDataDetails.City.replaceAll(" ", "-") +
        "_" +
        this.state.siteDataDetails.location.replaceAll(" ", "-");
    }
  };
  render() {
    var settingsBanner = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      swipe: true,
      arrows: false,
      draggable: true,
      lazyLoad: true,
    };
    let pathname = this.props.location.pathname;
    let currentUrl = "";
    const myArray = pathname.split("/");
    if (myArray.length > 0) {
      currentUrl = myArray[myArray.length - 1];
    }

    return (
      <div className="content locatorWrapper">

        {this.state.isLoader === 0 ? <div className="loder-wrapper"></div> : ""}
        <header className="header">
          <nav
            role="navigation"
            aria-label="main navigation"
            className="header-nav"
          >
            <div className="container header-nav-container">
              <div className="menuIcon" onClick={() => this.menuClick()}>
                <div className="menubar__icon menuimg">
                  <div className="menubar__icon01"></div>
                  <div className="menubar__icon01"></div>
                  <div className="menubar__icon01"></div>
                </div>
              </div>
              <div className="logo" title={this.state.dealerName}>
                <a href="https://www.axisbank.com/" rel="noopener noreferrer">
                  <img
                    src={logo}
                    alt="logo"
                    className="iocl-logo"
                    width="189"
                    height="45"
                  />
                </a>
              </div>
              <div className="menulist">
                <div className="menuClose" onClick={() => this.menuClose()}>
                  ×
                </div>
                <ul className="menulist-ul">
                  <li className="menulist-li menu-active">
                    <a href="https://www.axisbank.com/?utm_source=branch_locator&utm_medium=brand_website&_ga=2.69711696.1738061733.1666955655-231882724.1664189561" target="_blank" rel="noopener noreferrer">Home</a>
                  </li>
                  <li className="menulist-li">
                    <a href="https://www.axisbank.com/retail?cta=branch-explore-products&_ga=2.109174533.1251146387.1666000564-479982798.1665123386" target="_blank" rel="noopener noreferrer">Explore Products</a>
                  </li>
                  <li className="menulist-li">
                    <a href="https://www.axisbank.com/grab-deals/online-offers?cta=branch-grab-deals&_ga=2.75166738.1251146387.1666000564-479982798.1665123386" target="_blank" rel="noopener noreferrer">Grab Deals</a>
                  </li>
                  <li className="menulist-li">
                    <a href="https://www.axisbank.com/explore-make-payments?cta=branch-make-payment&_ga=2.137509808.1251146387.1666000564-479982798.1665123386" target="_blank" rel="noopener noreferrer">Make Payment</a>
                  </li>
                  <li className="menulist-li">
                    <a href="https://www.axisbank.com/bank-smart/internet-banking/getting-started?cta=branch-bank-smart&_ga=2.113890055.1251146387.1666000564-479982798.1665123386" target="_blank" rel="noopener noreferrer">Bank Smart</a>
                  </li>
                  {/* <li className="menulist-li">
                    <Link to="/home">Timeline</Link>
                  </li>
                  <li className="menulist-li">
                    <Link to="/home">Gallery</Link>
                  </li> */}
                  <li className="menulist-li menulist-liMob">
                    <>
                      <img
                        data-src={Phn}
                        className="head-phn lazyload"
                        alt="Phn"
                        width="44"
                        height="20"
                      />
                      <a className="phn-txt" href={"tel:18604195555"}>
                        {" "}
                        18604195555
                      </a>
                    </>
                  </li>
                </ul>
              </div>
              <div className="">
                <a href="https://omni.axisbank.co.in/axisretailbanking/" aria-label="axis bank" target="_blank" rel="noopener noreferrer">
                  <span className="user_icon_axis"></span>
                </a>
              </div>
            </div>
          </nav>
        </header>
        {/* <Helmet
          title={this.state.seoTitle}
          htmlAttributes={{ lang: "en" }}
          meta={[
            {
              name: `description`,
              content: this.state.seoDescription,
            },
            {
              name: `keywords`,
              content: this.state.seoKeywords,
            },
            {
              name: `tag`,
              content: this.state.SeoH1Tag,
            },
            {
              name: `twitter:title`,
              content: this.state.seoTitle,
            },
            {
              name: `twitter:description`,
              content: this.state.seoDescription,
            },
            {
              name: `facebook:title`,
              content: this.state.seoTitle,
            },
            {
              name: `facebook:description`,
              content: this.state.seoDescription,
            },
            {
              name: `og:title`,
              content: this.state.seoTitle,
            },
            {
              name: `og:image`,
              content:
                this.state.bannerData.length === 0
                  ? window.location.host + LocatorBannerimg
                  : this.state.cdnPath +
                  "production/" +
                  this.state.bannerData[this.state.bannerData.length - 1],
            },
            {
              name: `og:description`,
              content: this.state.seoDescription,
            },
            {
              name: `og:site_name`,
              content: this.state.dealerName,
            },
            {
              name: `og:url`,
              content: new URL(window.location.href),
            },
          ]}
        /> */}
        {/* <Helmet>
          {<title>{this.state.seoTitle}</title>}
          <link rel="canonical" href={new URL(window.location.href)} />
          <meta property="og:type" content="website" />
        </Helmet> */}
          {((this.state.getDataApiResult === "") || (this.state.getDataApiResult === "true")) ?
            <main role="main">
              <div className="container breadcrumb_padd">
                <nav className="breadcrumb-main" aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="https://www.axisbank.com/" onClick={() => this.navigateBreadCrumb("Dealer")} rel="noopener noreferrer">
                        Home
                      </a>
                    </li>

                    {this.state.siteDataDetails != null && (
                      <li className="breadcrumb-item">
                        <a href="https://branch.axisbank.com/" onClick={() => this.navigateBreadCrumb("State")}>
                          {/* {this.state.siteDataDetails.State} */}
                          Locate Us
                        </a>
                      </li>
                    )}
                    <li className="breadcrumb-item" aria-current="page">
                      {typeof this.state.dealerName !== "undefined" && this.state.dealerName !== "" ?
                        this.state.dealerName.length === 16 || this.state.dealerName.length === 13 ?
                          this.state.dealerName
                          :
                          this.state.dealerName.length > 13 && this.state.dealerName.includes("Axis Bank ATM ") ?
                            this.state.dealerName.slice(0, 13) + ", " + this.state.dealerName.substring(14)
                            :
                            this.state.dealerName.includes("Axis Bank Branch") &&
                            this.state.dealerName.slice(0, 16) + ", " + this.state.dealerName.substring(17)
                        : ""
                      }
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="container-fluid banner-main locator-banner-mb">
                <Slider {...settingsBanner}>
                  {this.state.bannerData.length === 0 ? (
                    <this.Preloader />
                  ) : (
                    this.state.bannerData.map((obj, i) =>
                      obj !== "" ? (
                        obj.includes("^^") ? (
                        <a href={obj.split("^^")[2]} key={i} target="_blank">
                            <img
                              src={MainBanner}
                              alt="loactor-banner"
                              className="bannerImages bannerImagesLoc"
                              width="1349"
                              height="211"
                            />
                            <ImageLoader
                              width="1349"
                              height="211"
                              src={
                                this.state.cdnPath +
                                "production/" +
                                obj.split("^^")[0]
                              }
                              className="image-banner-web"
                            >
                              <img alt="Error" width="1349" height="211" />
                              <div>Error!</div>
                              <this.Preloader />
                            </ImageLoader>
                            {obj.split("^^")[1] || obj.split("^^")[1] !== "" ?
                              <ImageLoader
                                width="480"
                                height="480"
                                src={
                                  this.state.cdnPath +
                                  "production/" +
                                  obj.split("^^")[1]
                                }
                                className="image-banner-mob"
                              >
                                <img alt="Error" width="480" height="480" />
                                <div>Error!</div>
                                <this.Preloader />
                              </ImageLoader>
                              :
                              <ImageLoader
                                width="480"
                                height="480"
                                src={sliderImg1}
                                className="image-banner-mob"
                              >
                                <img alt="Error" width="480" height="480" />
                                <div>Error!</div>
                                <this.Preloader />
                              </ImageLoader>
                            }
                          </a>
                        ) : (
                          <ImageLoader
                            width="1349"
                            height="211"
                            src={this.state.cdnPath + "production/" + obj}
                          >
                            <img alt="Error" width="1349" height="211" />
                            <div>Error!</div>
                            <this.Preloader />
                          </ImageLoader>
                        )
                      ) : (
                        ""
                      )
                    )
                  )}
                </Slider>
              </div>
              <div className="branch_outer_padd">
                <div className="container">
                  <section className="branch-details-wrapper">
                    <div className="row m-0">
                      <div className="col-lg-4 col-md-12 px-0 pr-lg-1">
                        <div>
                          <h1 className="branch-title" title={"AXIS BANK - " + this.state.location + "," + this.state.City}>

                            {this.state.getDataFlag === true ? (
                              typeof this.state.dealerName !== "undefined" && this.state.dealerName !== "" ?(
                                this.state.dealerName.length === 16 || this.state.dealerName.length === 13 ?
                                  (this.state.dealerName)
                                  :
                                  this.state.dealerName.includes("Axis Bank ATM ")||this.state.dealerName.includes("Axis Bank Branch")?(
                                  this.state.dealerName.length > 13 && this.state.dealerName.includes("Axis Bank ATM ") ?(
                                    this.state.dealerName.slice(0, 13) + ", " + this.state.dealerName.substring(14))
                                    :
                                    this.state.dealerName.includes("Axis Bank Branch") &&
                                    this.state.dealerName.slice(0, 16) + ", " + this.state.dealerName.substring(17)):this.state.dealerName
                              ): ""

                            ) : "fetching...."
                            }
                          </h1>
                        </div>
                        <div className="branch-details-main">
                          <div className="branch-details-topsection">
                            {(this.state.branchId !== "NULL" && this.state.branchId !== "") && this.state.dealerPhNo.split("|")[7] !== "Loan Centre" ?
                              <div className="label-text-list">
                                <div className="label-text-left">Branch ID</div>
                                <div className="label-column"> : </div>
                                <div className="label-text-right">
                                  {this.state.getDataFlag === true ?
                                    // this.state.dealerPhNo.split("|")[0]
                                    this.state.branchId
                                    : "fetching...."
                                  }
                                </div>
                              </div>
                              : ""
                            }
                            {((this.state.dealerPhNo.split("|")[1] !== "NULL") && (this.state.dealerPhNo.split("|")[1] !== "undefined") && (this.state.dealerPhNo.split("|")[1] !== "") && (this.state.dealerPhNo.split("|")[1] !== "0")) ?
                              <div className="label-text-list">
                                <div className="label-text-left">IFSC</div>
                                <div className="label-column"> : </div>
                                <div className="label-text-right">
                                  {/* UTIB0000802 */}
                                  {this.state.getDataFlag === true ?
                                    this.state.dealerPhNo.split("|")[1]
                                    : "fetching...."
                                  }
                                </div>
                              </div>
                              : ""
                            }
                            <div className="label-text-list">
                              <div className="label-text-left">Address</div>
                              <div className="label-column"> : </div>
                              <div className="label-text-right">
                                {this.state.getDataFlag === true ?
                                ((this.state.dealerPhNo.split("|")[7]) && (this.state.dealerPhNo.split("|")[7] !== "undefined") && (this.state.dealerPhNo.split("|")[7] !== "")) &&
                                ((this.state.dealerPhNo.split("|")[7].includes("ATM")) ||  (this.state.dealerPhNo.split("|")[7].includes("Branch")))? 
                                this.state.Address
                                :
                                  this.state.Address + ", " + this.state.City + ", " + this.state.State + ", " + this.state.pincode
                                  : "fetching...."
                                }
                              </div>
                            </div>
                            {((this.state.dealerPhNo.split("|")[3]) && (this.state.dealerPhNo.split("|")[3] !== "undefined") && (this.state.dealerPhNo.split("|")[3] !== "")) &&
                              <div className="label-text-list">
                                <div className="label-text-left">Landmark</div>
                                <div className="label-column"> : </div>
                                <div className="label-text-right">
                                  {this.state.getDataFlag === true ?
                                    this.state.dealerPhNo.split("|")[3]
                                    : "fetching...."
                                  }
                                </div>
                              </div>
                            }
                            <div className="label-text-list">
                              <div className="label-text-left">Hours</div>
                              <div className="label-column"> : </div>
                              <div className="label-text-right">
                              {this.state.getDataFlag === true ?
                                this.state.bussinessHrs === "Open 24 hours" ?
                                <span> {this.state.bussinessHrs}</span>
                                :
                                ((moment(this.state.bussinessHrs?.split("|")[2], 'hh:mm A').format('HH:mm') > moment(this.state.currentTimeee, 'hh:mm A').format('HH:mm')) && (moment().day() != 7)) ?
                                <span className="text-danger">Will open at {this.state.bussinessHrs?.split("|")[2]}</span> :
                                ((moment(this.state.bussinessHrs?.split("|")[3], 'hh:mm A').format('HH:mm') > moment(this.state.currentTimeee, 'hh:mm A').format('HH:mm')) && (moment().day() != 7)) ?
                                "Open until " + this.state.bussinessHrs?.split("|")[3] + "" :
                                <span className="text-danger">Closed for the day</span>
                              : "fetching...."
                              }


                                {/* {this.state.getDataFlag === true ?
                                  this.state.dealerPhNo.split("|")[7] !== undefined &&
                                    this.state.dealerPhNo
                                      .split("|")[7]
                                      .split(",")
                                      .includes("ATM") ? (
                                    <span className="text-danger"> Open 24 hours </span>
                                  )
                                  :
                                  ((moment(this.state.openTime, 'hh:mm A').format('HH:mm') > moment(this.state.currentTimeee, 'hh:mm A').format('HH:mm')) && (moment().day() != 7)) ?
                                  <span className="text-danger">Will open at {this.state.openTime}</span> :
                                  ((moment(this.state.closedTime, 'hh:mm A').format('HH:mm') > moment(this.state.currentTimeee, 'hh:mm A').format('HH:mm')) && (moment().day() != 7)) ?
                                  "Open until " + this.state.closedTime + "" :
                                  <span className="text-danger">Closed for the day</span>
                                  : "fetching...."
                                } */}
                              </div>
                            </div>
                            <div className="label-text-list">
                              <div className="label-text-left">Contact Number</div>
                              <div className="label-column"> : </div>
                              <div className="label-text-right">
                                {this.state.getDataFlag === true ?
                                  <a
                                    className="anchor-link-black"
                                    href={"tel:" + this.state.dealerPhNo}
                                  >
                                    {this.state.dealerPhNo.split("|")[5]}
                                  </a>
                                  : "fetching...."
                                }
                              </div>
                            </div>
                            <div className="label-text-list">
                              <div className="label-text-left">Pincode</div>
                              <div className="label-column"> : </div>
                              <div className="label-text-right">
                                {this.state.getDataFlag === true ?
                                  this.state.pincode
                                  : "fetching...."
                                }
                              </div>
                            </div>
                          </div>
                          <div className="label-text-list labl_paddcheck mt-3">
                            {this.state.getDataFlag === true &&
                              this.state.dealerPhNo.split("|")[7] !== undefined &&
                              this.state.dealerPhNo
                                .split("|")[7]
                                .split(",").map((bankService, i) => (
                                  bankService !== "" ?
                                    <h2 className="label-text-right labl_text_width" key={i}>
                                      <img
                                        alt="Branch"
                                        src={checkpoxIcon}
                                        className="mb-1 mr-2"
                                        width="15"
                                        height="15"
                                      ></img>
                                      {bankService}
                                    </h2>
                                    : ""
                                ))
                            }
                          </div>
                          {this.state.getDataFlag === true &&
                            <div className="explore-btn">
                              {this.state.dealerPhNo.split("|")[8] !== undefined &&
                                this.state.dealerPhNo
                                  .split("|")[8]
                                  .split(",")
                                  .join(" ")
                                  .includes("Explore Products") && (
                                  <a
                                    href={
                                      this.state.dealerPhNo
                                        .split("|")[8]
                                        .split(",")[1]
                                    }
                                    target="_blank" rel="noopener noreferrer"
                                  >
                                    <button className="exploreButton" type="button">
                                      Explore Products
                                    </button>
                                  </a>
                                )}
                            </div>
                          }
                        </div>
                      </div>

                      <div className="col-lg-5 col-md-7 px-0 pr-lg-2 pl-lg-2 mapdivs">
                        <div
                          className="googleMapdiv"
                        >
                          <Suspense fallback={<div>Loading...</div>}>
                            {(this.state.longitude && this.state.latitude) ?
                              <GoogleMapp
                                dealerName= {this.state.dealerName}
                                address={this.state.Address}
                                StateValue={this.state.State}
                                city={this.state.City}
                                pinCode={this.state.pincode}
                                //key={this.state.longitude}
                                longitude={this.state.longitude}
                                latitude={this.state.latitude}
                                googleAddressPin=""
                                setGoogleAddressPin={(googleAddressPin) => {
                                  this.setState({ googleAddressPin });
                                }}
                                selectGoogleAddressPin={(
                                  googleAddressPin,
                                  lat,
                                  lng
                                ) => {
                                  this.setState({
                                    googleAddressPin: googleAddressPin,
                                    latitude: lat,
                                    longitude: lng,
                                  });
                                }}
                                onMarkerDragEnd={(lat, lng) => {
                                  this.setState({ latitude: lat, longitude: lng });
                                }}
                              />
                              :
                              <div className="map-not-available">
                                <h6 className="text-muted">Map not available</h6>
                              </div>
                            }
                          </Suspense>
                        </div>
                      </div>

                      <div className="col-lg-3 col-md-5 px-0 pl-lg-2 pl-md-3">
                        <a href=" https://www.axisbank.com/retail/accounts/savings-account/amaze-savings-account?cta=branch-locator-banner" target="_blank" rel="noopener noreferrer">
                          <img src={SavingsImg} className="img-fluid formimgheight" alt="Amaze Savings Account" width={600} height={1171}></img>
                        </a>
                      </div>
                    </div>
                  </section>
                </div>

                <Suspense fallback={<div>Loading...</div>}>
                {this.state.getDataFlag === true ? (
                  <>
                  <LocatorBranchInfo
                    key={this.state.dealerName}
                    location={this.state.location}
                    City={this.state.City}
                    dealerName={this.state.dealerName}
                    branchInfoDetails={this.state.dealerPhNo}
                    address={this.state.Address}
                    pinCode={this.state.pincode}
                    categoryType={this.state.categoryType}
                    latitude={this.state.latitude}
                    longitude={this.state.longitude}
                    bussinessHrs={this.state.bussinessHrs}
                    servicesOptions={this.state.servicesOptions}
                    languages={this.state.languages}
                    parkingOptions={this.state.parkingOptions}
                    fuelAvailable={this.state.fuelAvailable}
                    paymentMethod={this.state.paymentMethod}
                    progressBlog={this.state.progressBlog}
                    timing={this.state.timing}
                    s3imageurl={this.state.s3imageurl}
                    cdnPath={this.state.cdnPath}
                    youtubeVideos={this.state.youtubeVideos}
                    galleryVideos={this.state.galleryVideos}
                    offers={this.state.offers}
                    about={this.state.about}
                    paymentMethods={this.state.paymentMethods}
                    tags={this.state.tags}
                    bankservices={this.state.bankservices}
                    placeId={this.state.placeId}
                    loc_ratings= {this.state.loc_ratings}
                    // city = {this.state.City}
                    State={this.state.State}
                    getNearBranchesAtm={this.state.getNearBranchesAtm}
                    getNearLocality = {this.state.getNearLocality}
                  />

                  <Dealership key={this.state.getNearBranchesAtm}
                    currentTimeee={this.state.currentTimeee}
                    openTime={this.state.openTime}
                    closedTime={this.state.closedTime}
                    getNearBranchesAtm={this.state.getNearBranchesAtm} />
                  </>
                ):""}
                </Suspense>
              </div>



              <div className="menu_add_desktop">
                <span>
                  <img
                    src={ApplyButton}
                    alt="Apply Now"
                    title="Apply Now"
                    width="44"
                    height="122"
                    className="desktopApply"
                  />
                  {/* <span id="modal">
                    <img
                      src={ApplyButtonMBl}
                      alt="Apply Now"
                      title="Apply Now"
                      width="414"
                      height="57"
                      className="mobileApply"
                    />
                  </span> */}
                </span>
                <div className="modalApply" id="applyModal">
                  <div className="modal-wrap">
                    <div className="applyImgs">
                      <img src={Close} alt="close" id="closeModal" />
                    </div>
                    <ul>
                      <li>
                        <a
                          href="https://application.axisbank.co.in/webforms/onlineform/saving-account/web/savingsaccount.aspx?cta=branch-locator-asap-account-applynow"
                          target="_blank" rel="noopener noreferrer"
                        >
                          Axis ASAP Account
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://application.axisbank.co.in/webforms/onlineform/personal-loan/web/personalloan.aspx?cta=branch-locator-personal-loan-applynow"
                          target="_blank" rel="noopener noreferrer"
                        >
                          Instant Personal Loan
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://clctab.axisbank.co.in/DigitalChannel/WebForm/?index6&amp;cta=branch-locator-credit-card-applynow"
                          target="_blank" rel="noopener noreferrer"
                        >
                          Credit Card
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://application.axisbank.co.in/webforms/onlineform/home-loan/web/homeLoan.aspx?cta=branch-locator-home-loan-applynow"
                          target="_blank" rel="noopener noreferrer"
                        >
                          Home Loan
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://application.axisbank.co.in/webforms/onlineform/car-loan/web/carloan.aspx?cta=branch-locator-car-loan-applynow"
                          target="_blank" rel="noopener noreferrer"
                        >
                          Car Loan
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.axisbank.com/retail/investment/mutual-funds/buy-top-mutual-funds?cta=branch-locator-mutual-fund-applynow"
                          target="_blank" rel="noopener noreferrer"
                        >
                          Mutual Fund SIP
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="menu_add_desktop_link">
                  <ul>
                    <li>
                      <a
                        href="https://application.axisbank.co.in/webforms/onlineform/saving-account/web/savingsaccount.aspx?cta=branch-locator-asap-account-applynow"
                        target="_blank" rel="noopener noreferrer"
                      >
                        Axis ASAP Account
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://application.axisbank.co.in/webforms/onlineform/personal-loan/web/personalloan.aspx?cta=branch-locator-personal-loan-applynow"
                        target="_blank" rel="noopener noreferrer"
                      >
                        Instant Personal Loan
                      </a>
                    </li>
                    <li>
                         <a
                        href="https://web.axisbank.co.in/DigitalChannel/WebForm/?index6&axisreferralcode=branch-locator-website&cta=branch-locator-credit-card-applynow"
                        target="_blank" rel="noopener noreferrer"
                      >
                        Credit Card
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://application.axisbank.co.in/webforms/onlineform/home-loan/web/homeLoan.aspx?cta=branch-locator-home-loan-applynow"
                        target="_blank" rel="noopener noreferrer"
                      >
                        Home Loan
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://application.axisbank.co.in/webforms/onlineform/car-loan/web/carloan.aspx?cta=branch-locator-car-loan-applynow"
                        target="_blank" rel="noopener noreferrer"
                      >
                        Car Loan
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.axisbank.com/retail/investment/mutual-funds/buy-top-mutual-funds?cta=branch-locator-mutual-fund-applynow"
                        target="_blank" rel="noopener noreferrer"
                      >
                        Mutual Fund SIP
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </main>
            :
            this.state.siteDataDetails === null &&
            <PageNotFound/>
          }
        <Suspense fallback={<div>Loading...</div>}>
          <Footerr
            ishomeActive={"0"}
            facebook={this.state.facebook}
            googlepluspage={this.state.googlepluspage}
            instagram={this.state.instagram}
            twitter={this.state.twitter}
            youtube={this.state.youtube}
            linkedin={this.state.linkedin}
          />
        </Suspense>
      </div>
    );
  }
}
export default Locator;
