import React, { Component } from 'react'

export class RepresentativeOffice extends Component {
  render() {
    return (
      <div className="representative-wrapper">
        <div className="container p-0">
          <div className="col-md-12 p-0 my-4 my-md-5">
            <button className="LgButton" type="button">NRI Representative Offices</button>
          </div>
          <div className='row'>
            <div className="col-md-4 mb-4 mb-md-5 px-2">
              <div className="col-md-12 ovearseas-div">
                <h4 className="main-head-lg">Axis Bank Limited, Dubai Representative Office </h4>
                <div className="my-2 ovearseas-add-p">
                  <p className="sub-head-22">Office No.105, 1st Floor, Al Safa Business Centre, Plot No.318-883, Street No.19, PO Box.122504, Opp. Dubai Central Post Office, Karama Dubai, UAE</p>
                </div>
                <p className="sub-head-22">Tel : 00971-43343688</p>
              </div>
            </div>
            <div className="col-md-4 mb-4 mb-md-5 px-2">
              <div className="col-md-12 ovearseas-div">
                <h4 className="main-head-lg">Axis Bank Limited, Abu Dhabi Representative Office  </h4>
                <div className="my-2 ovearseas-add-p">
                  <p className="sub-head-22">P O Box No.113498, Office No.1003, 10th Floor, Al Masaood Tower,
                    Near Millenium Hotel, Hamdan Street, Abu Dhabi UAE</p>
                </div>
                <p className="sub-head-22">Tel : 00971-26281668</p>
              </div>
            </div>
            <div className="col-md-4 mb-4 mb-md-5 px-2">
              <div className="col-md-12 ovearseas-div">
                <h4 className="main-head-lg">Axis Bank Limited, Sharjah Representative Office  </h4>
                <div className="my-2 ovearseas-add-p">
                  <p className="sub-head-22">1312-1313, Al Hind Tower, PO Box 39712, Besides
                    Qasba Canal, Al Qasba, Sharjah, UAE.</p>
                </div>
                <p className="sub-head-22">Tel : 00971-65253330</p>
              </div>
            </div>
          </div>
        </div>
        <div className="container p-0 pt-1">
          <div className="col-md-12 p-0 mb-4 mb-md-5">
            <button className="LgButton" type="button">Overseas Branch Offices</button>
          </div>
          <div className='row'>
            <div className="col-md-4 mb-4 mb-md-5 px-2">
              <div className="col-md-12 ovearseas-div">
                <h4 className="main-head-lg">Axis Bank Limited - DIFC Branch</h4>
                <h5 className="main-head-md">regulated by the DFSA</h5>
                <div className="my-2 ovearseas-add-p">
                  <p className="sub-head-22">2804, Al Fattan Currency House, Tower 2, DIFC, P.O. Box 506593, Dubai, UAE</p>
                </div>
                <p className="sub-head-22">Tel : 00971 4 3735555</p>
              </div>
            </div>
            <div className="col-md-4 mb-4 mb-md-5 px-2">
              <div className="col-md-12 ovearseas-div">
                <h4 className="main-head-lg">Axis Bank Limited - Singapore Branch</h4>
                <div className="my-2 ovearseas-add-p">
                  <p className="sub-head-22">9 Raffles Pl, #16-20 Republic Plaza II, Singapore 048619</p>
                </div>
                <p className="sub-head-22">Tel: +6565719229</p>
              </div>
            </div>
            <div className="col-md-4 mb-4 mb-md-5 px-2">
              <div className="col-md-12 ovearseas-div">
                <h4 className="main-head-lg">Axis Bank Limited, IFSC Banking Unit </h4>
                <div className="my-2 ovearseas-add-p">
                  <p className="sub-head-22">Unit No 1401, 14th Floor, Brigade International Financial Centre (BIFC) Building, Gift Multi-Services SEZ, Gift City, Gandhinagar – 382355 Gujarat</p>
                </div>
                <p className="sub-head-22">Tel: +91 79 66745200</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default RepresentativeOffice