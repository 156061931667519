import axios from "axios";
var CryptoJS = require("crypto-js");
//test
//  const newBaseUrl="https://vmt5u3tjuc.execute-api.ap-south-1.amazonaws.com/test/getcmsdata"
// const newBaseUrl = "https://vmt5u3tjuc.execute-api.ap-south-1.amazonaws.com/axis-test/getcmsdata";
// const newBaseUrl="https://vmt5u3tjuc.execute-api.ap-south-1.amazonaws.com/axis_test_noToken/getcmsdata"
// const newBaseUrl = "https://j617xjxwjd.execute-api.ap-south-1.amazonaws.com/axis_bank_test/getCmsData-V18";


//prod
// const newBaseUrl = "https://d1qu79lemlspou.cloudfront.net/axis-bank-prod/getcmsdata";
// const newBaseUrl = "https://vmt5u3tjuc.execute-api.ap-south-1.amazonaws.com/axis-bank-prod/getcmsdata";
const newBaseUrl = "https://j617xjxwjd.execute-api.ap-south-1.amazonaws.com/axis_bank_prod/getCmsData-V18";

// export function apiCallingPost(postData) {
//     return axios.post(newBaseUrl, postData)
//       .then((data) => data)
//       .then((res) => res)
//   }

// Tokenisation Part
export function apiCallingPost(postData) {
	var CryptoJS = require("crypto-js");
	var tokenData;
	const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY; // 32 DIGITS
	  //console.log("ApiData",postData)
	if (!postData == "") {
		//var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify(postData)), process.env.REACT_APP_ENCRYPTION_KEY).toString();
		var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(postData), process.env.REACT_APP_ENCRYPTION_KEY).toString();

		tokenData = {
			token: ciphertext,
		};
	} else {
		tokenData = "";
	}
	//  console.log("token",tokenData)
	return axios
		.post(newBaseUrl, tokenData)
		.then((data) => {
			//console.log("data",data)
			let obj = {};
			var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
			//var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
			var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
			  //console.log("ApiDataDe",decryptedData)
			obj = {
				data: decryptedData,
			};
			return obj;
		})
		.then((res) => res);
}

// export function apiCallingCurrentLocation(postData) {
//   // console.log("postData---lat----", postData);
//     return axios.get("https://nominatim.openstreetmap.org/reverse?format=json&lat="+postData.latitude+"&lon="+postData.longitude)
//       .then((data) => data)
//       .then((res) => res)
//   }

// Tokenisation Part
// export function getSiteData(functionName, siteId) {

//   var tokenData;
//   const ENC_KEY = process.env.REACT_APP_ENCRYPTION_KEY // 32 DIGITS
//   //console.log("ENC_KEY",ENC_KEY)
//   var postData = {
//     "functionName": functionName,
//     "siteId": siteId
//   }
//   //console.log("ApiData",postData)

//   if(!postData == "")
//   {
//       var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(JSON.stringify(postData)), ENC_KEY).toString();
//        tokenData = {
//           "token": ciphertext
//       }
//   }
//   else{
//        tokenData = ""
//   }
//   //console.log("token",tokenData)
//   return axios.post(newBaseUrl, tokenData)
//   .then(data => {
//     //console.log("data",data)
//     let obj = {}
//     var bytes = CryptoJS.AES.decrypt(data.data.token, ENC_KEY);
//     var decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
//     //console.log("ApiDataD",decryptedData)
//     obj = {
//         data:decryptedData
//     }
//     return obj;
//   })

//     .then((res) => res)
//  }

// export function getSiteData(functionName, siteId) {
//   console.log(newBaseUrl + '?functionName=' + functionName + '&siteId=' + siteId  )
//    return fetch(newBaseUrl + '?functionName=' + functionName + '&siteId=' + siteId  )
//      .then(data => data.json())
//      .then(res => res);
//  }
