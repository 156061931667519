import React, { Component } from 'react'

export default class OverseanceNotice extends Component {
  render() {
    return (
      
      <div className="overseance-notice">
        <div className="container p-0">
          <div className="col-md-12 p-0">
            <p className="sub-head-22 mb-4">
              <span className="num-bold">1.</span>
              <span> To view country-wise list of Toll-free numbers
                <a className="ml-1" href="https://www.axisbank.com/bank-smart/phone-banking/nri-phone-banking" title="click Here" target="_blank" rel="noopener noreferrer">Click Here</a>
              </span>
            </p>
            {/* <p className="sub-head-22 mb-4">
              <span className="num-bold">2.</span>
              <span> 
              Click to Know applicable charges for Trade and Finance services at our Branches at 
              <a className="ml-1" href="https://web.archive.org/web/20211022192650/https://branch.axisbank.com/VendorpageTheme/Enterprise/EThemeForAxisBankUpdated/pdf/schedule_of_charges_dubai.pdf" title="Dubai" target="_blank" rel="noopener noreferrer">Dubai</a>, 
              <a className="ml-1" href="https://web.archive.org/web/20211022183527/https://branch.axisbank.com/VendorpageTheme/Enterprise/EThemeForAxisBankUpdated/pdf/schedule_of_charges_singapore.pdf" title="Singapore" target="_blank" rel="noopener noreferrer">Singapore</a> & 
              <a className="ml-1" href="https://web.archive.org/web/20211022185709/https://branch.axisbank.com/VendorpageTheme/Enterprise/EThemeForAxisBankUpdated/pdf/schedule_of_charges_gift_city.pdf" title="GIFT City" target="_blank" rel="noopener noreferrer">GIFT City</a> branches.
              </span>
            </p> */}
            <p className="sub-head-22 mb-4">
              <span className="num-bold">2.</span>
              <span> To locate Aadhaar Enrolment Centres
                <a className="ml-1" href="https://www.axisbank.com/docs/default-source/default-document-library/axis-bank_aadhaar-enrolment-centres.pdf" title="click Here" target="_blank" rel="noopener noreferrer">Click Here</a>
              </span>
            </p>
            <p className="sub-head-22 mb-4">
              <span className="num-bold">3.</span>
              <span> 
              For fund transfer to other banks on 2nd and 4th Saturdays, you can use the IMPS service, which is available 24*7.
              </span>
            </p>
            <p className="sub-head-22 mb-4">
              <span className="num-bold">4.</span>
              <span> 
              NEFT transactions will be available 24x7 on Internet (Corporate & Retail) and Mobile Banking Channels w.e.f. 16th December 2019 as per details given below : From 8.00 AM to 6.30 PM – As per customer approval limit From 6.30 PM to 8.00 AM (including 2nd & 4th Saturday, Sunday & RTGS Holidays) – Less than INR 1 Crore (Transactions which are INR 1 Crore or above will be processed on the next RTGS day)
              </span>
            </p>
          </div>
        </div>
      </div>
    )
  }
}
