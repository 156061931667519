import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Home from "./home/home";
import Locator from "./home/locator";
import OverseasPresence from "./home/overseas-presence"; 
import PageNotFound from "./home/page-not-found"; 
import { HelmetProvider } from "react-helmet-async";
// import * as serviceWorker from "./serviceWorker";

const rootElement = document.getElementById("root")

if (rootElement.hasChildNodes()) {
  ReactDOM.render(

    <HelmetProvider>
      <Router>
        <Switch>
          <Route
            exact
            path={["/",
              // "/home",
              "/location",
              // "/home_:State",
              "/location/:State",
              // "/home_:State_:City",
              "/location/:State?/:City?",
              "/location/:State?/:City?/:Location?",
              // "/home_:State_:City_:location"
            ]}
            component={Home}
          />
          <Route
            exact
            path={["/:slug/Home"]}
            component={Locator}
            strict
          />
          <Route
            exact
            path={["/axis-bank-overseas-branches"]}
            component={OverseasPresence}
            strict
          />
          <Route
            exact
            component={PageNotFound}
            strict
          />
        </Switch>

      </Router>
    </HelmetProvider>,
    rootElement
  );
}
else {

  ReactDOM.hydrate(

    <HelmetProvider>
      <Router>
        <Switch>
          <Route
            exact
            path={["/",
              // "/home",
              "/location",
              // "/home_:State",
              "/location/:State",
              // "/home_:State_:City",
              "/location/:State/:City",
              "/location/:State?/:City?/:Location?",
              // "/home_:State_:City_:location"
            ]}
            component={Home}
          />
          <Route
            exact
            path={["/:slug/Home"]}
            component={Locator}
            strict
          />
          <Route
            exact
            path={["/axis-bank-overseas-branches"]}
            component={OverseasPresence}
            strict
          />
          <Route
            exact
            component={PageNotFound}
            strict
          />
        </Switch>
      </Router>
    </HelmetProvider>,
    rootElement
  );
}

// serviceWorker.unregister();
