import React, { Component, Suspense } from "react";
const Banner = React.lazy(() => import('../home/home-banner'));
class PageNotFound extends Component {

  render() {
    return (
      <main role="main">
        <div className="container">
          <nav className="breadcrumb-main" aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="https://www.axisbank.com/?utm_source=branch-atm-locator&utm_medium=branch-locator&_ga=2.95919580.1738061733.1666955655-231882724.1664189561"> Home </a>
              </li>
              <li className="breadcrumb-item" aria-current="page"
              style={{cursor:"auto"}}>
                Page Not Found
              </li>
            </ol>
          </nav>
        </div>
        <div className="container pb-4 pb-md-5 pt-2">
          <div className="row">
              <div className="col-md-12">
                <section className="pnf-wrap">
                    <p className="text-md-right ">This page might have been removed or is temporarily unavailable</p>
                    <h3 className="oops">Oops! 404 Error</h3>
                    <p className="sub-para">We can't seem to find the page your looking for.</p>
                    <div className="dispFlex">
                      <div className="left-data">
                          <p>Here are some helpful links instead</p>
                          <div className="help-links">
                            <ul className="p-0">
                                <li><a href="https://www.axisbank.com/retail/accounts/savings-account">Savings Account</a></li>
                                <li><a href="https://www.axisbank.com/business-banking/current-account">Current Account</a></li>
                                <li><a href="https://www.axisbank.com/retail/cards/credit-card">Credit Cards</a></li>
                                <li><a href="https://www.axisbank.com/retail/loans/personal-loan/features-benefits">Personal Loan</a></li>
                                <li><a href="https://www.axisbank.com/retail/loans/home-loan">Home Loan</a></li>
                                <li><a href="https://www.axisbank.com/interest-rate-on-deposits">FD Interest Rates</a></li>
                            </ul>
                          </div>
                          <div className="error-btn-links">
                            <a href="https://www.axisbank.com/">Go to Homepage</a>
                          </div>
                      </div>
                      <div className="error-bnr-img">
                        <img src="https://www.axisbank.com/images/default-source/revamp_new/pnf-banner.png" className="img-fluid" 
                        alt="error-image" width="300" height="300"/></div>
                    </div>
                </section>
              </div>
          </div>
        </div>
      </main>
    );
  }
}
export default PageNotFound;
