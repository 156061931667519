import React, { Component, Suspense } from "react";
import $ from "jquery";
import RepresentativeOffice from "./overseance-representative-office";
import OverseanceNotice from "./overseance-notice";
class OverseasPresence extends Component {

  render() {
    return (
      
      <div className="overseas-wrappers">
        <RepresentativeOffice />
        <OverseanceNotice />
      </div>
    
    );
  }
}
export default OverseasPresence;
