import React, { Component, Suspense } from "react";
import $ from "jquery";
import PageFoundSub from "./page-not-found-sub"
const Header = React.lazy(() => import('../layouts/header'));
const Footer = React.lazy(() => import('../layouts/footer'));

class PageNotFound extends Component {

  render() {
    return (
      <div className="content home_wrapper">
        <Suspense fallback={<div>Loading...</div>}>
          <Header />
          <PageFoundSub />
          
          <Footer
            ishomeActive={"1"}
          />
        </Suspense>
      </div>
    );
  }
}
export default PageNotFound;
