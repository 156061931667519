import React, { Component, Suspense } from "react";
import { Helmet } from "react-helmet";
import $ from "jquery";
const Header = React.lazy(() => import('../layouts/header'));
const Footer = React.lazy(() => import('../layouts/footer'));
const Banner = React.lazy(() => import('../home/home-banner'));
const SearchStore = React.lazy(() => import('../home/home-search-store'));
const OverseasPresence = React.lazy(() => import('../home/overseas-presence'));
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOverseasPresence : window.location.href.indexOf("overseas-presence")
    }
  }
  componentDidMount() {
   // alert(window.location.href)
    // const paramsUrl = this.props.match.params;
    // console.log("*******params--", params)
  }

  render() {
    const paramsUrl = this.props.match.params;
    let pathname = this.props.location.pathname;
    let currentUrl = "";
    const myArray = pathname.split("/");
    if (myArray.length > 0) {
      currentUrl = myArray[myArray.length - 1];
    }
    return (
      <div className="content home_wrapper">
        <Suspense fallback={<div>Loading...</div>}>
          <Header />
          <main role="main">
            <div className="container">
              <nav className="breadcrumb-main" aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="https://www.axisbank.com/?utm_source=branch-atm-locator&utm_medium=branch-locator&_ga=2.95919580.1738061733.1666955655-231882724.1664189561"> Home </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    Locate Us
                  </li>
                </ol>
              </nav>
            </div>
            <Banner
            />
            {this.state.isOverseasPresence != "-1" ?
              <OverseasPresence />
            :
              <SearchStore
              pathname={pathname}
              paramsUrl={paramsUrl}
              url_location={this.props.location} />
            }
            
          </main>
          <Footer
            ishomeActive={"1"}
          />
        </Suspense>
      </div>
    );
  }
}
export default Home;
